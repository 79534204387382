<template>
  <div class="d-flex d-flex-c d-flex-between flex-1">
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Audience age"
        chart-type="bar"
        wrapper-type="large f-height"
        :chart-config="audienceAge"
      />
      <chart-wrapper
        class="ml-1"
        label="Gender"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="audienceGender"
      />
    </div>
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Audience country"
        chart-type="bar"
        wrapper-type="large f-height"
        :chart-config="audienceCountry"
      />
      <chart-wrapper
        class="ml-1"
        label="Audience city"
        chart-type="bar"
        wrapper-type="large f-height"
        :chart-config="audienceCity"
      />
    </div>
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Follower activity"
        chart-type="heatmap"
        wrapper-type="large f-height"
        :chart-config="followerActivity"
      />
    </div>
  </div>
</template>

<script>
import dayjs, { codes, formatChartDate } from "@/utils/timezone";
import { areaChartOpts, barCharOpts, smallAreaChartOpts } from "@/utils/chart";
import { timeTable } from "@/utils/constants";

const ageCategores = [
  "13-17",
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
];

const genders = [
  { category: "M", name: "Male" },
  { category: "F", name: "Female" },
];

export default {
  name: "InstagramAudience",
  components: {
    ChartWrapper: () =>
      import("@/components/").then((res) => {
        return res.ChartWrapper;
      }),
  },
  props: {
    metrics: { type: Array, default: () => [] },
  },
  computed: {
    audienceAge() {
      const genderAgeMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "audience_gender_age",
        ) || [])[0] || {};

      const { data = [] } = genderAgeMetrics;

      const seriesObj = Object.values(
        ageCategores.reduce((acc, curr) => {
          acc[curr] = data.reduce((a, c) => {
            const totalCount = Object.entries(c.count)
              .filter(([k, v]) => k.split(".")[1] == curr)
              .reduce((b, [k, v]) => {
                return b + v;
              }, 0);
            return a + totalCount;
          }, 0);
          return acc;
        }, {}),
      ).map((v) => v / data.length);

      const series = [
        {
          name: "Aduience age",
          data: seriesObj,
        },
      ];

      return {
        series,
        chartOptions: {
          ...barCharOpts,
          xaxis: {
            ...barCharOpts.xaxis,
            categories: ageCategores,
          },
        },
      };
    },
    audienceGender() {
      const genderAgeMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "audience_gender_age",
        ) || [])[0] || {};

      const { data = [] } = genderAgeMetrics;
      const xaxis = ["M", "F"];

      const seriesObj = Object.entries(
        genders.reduce((acc, curr) => {
          acc[curr.name] = data.reduce((a, c) => {
            const totalCount = Object.entries(c.count)
              .filter(([k, v]) => k.split(".")[0] == curr.category)
              .reduce((b, [k, v]) => {
                return b + v;
              }, 0);
            return a + totalCount;
          }, 0);
          return acc;
        }, {}),
      )
        .map(([k, v]) => ({ [k]: v / data.length }))
        .reduce((acc, curr) => {
          acc = {
            ...acc,
            ...curr,
          };
          return acc;
        }, {});

      const labels = Object.keys(seriesObj);
      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    audienceCountry() {
      const likeCountsMetrics =
        (this.metrics.filter((metric) => metric.type === "audience_country") ||
          [])[0] || {};

      const { data = [] } = likeCountsMetrics;

      const seriesObj = Object.entries(
        codes.reduce((acc, curr) => {
          acc[curr.name] =
            data.reduce((a, c) => {
              return a + c.count[curr.cca2];
            }, 0) / data.length;

          return acc;
        }, {}),
      )
        .filter(([key, value]) => value)
        .sort((a, b) => {
          const aVal = Object.values(a)[1];
          const bVal = Object.values(b)[1];

          if (aVal > bVal) {
            return -1;
          } else if (aVal < bVal) {
            return 1;
          }

          return 0;
        })
        .map(([k, v]) => ({ [k]: v }));

      const xaxis = seriesObj.map((obj) => Object.keys(obj)[0]).slice(0, 10);
      const values = seriesObj.map((obj) => Object.values(obj)[0]).slice(0, 10);

      const series = [
        {
          name: "Aduience country",
          data: values,
        },
      ];

      return {
        series,
        chartOptions: {
          ...barCharOpts,
          xaxis: {
            ...barCharOpts.xaxis,
            categories: xaxis,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },
      };
    },
    audienceCity() {
      const audienceCityMetrics =
        (this.metrics.filter((metric) => metric.type === "audience_city") ||
          [])[0] || {};

      const { data = [] } = audienceCityMetrics;

      const seriesObj = Object.entries(
        data.reduce((acc, curr) => {
          Object.entries(curr.count)
            .sort(([, aCount], [, bCount]) => {
              if (aCount > bCount) {
                return -1;
              }
              if (aCount < bCount) {
                return 1;
              }

              return 0;
            })
            .forEach(([v, c]) => {
              acc[v] = {
                value: c + ((acc[v] || {}).value || 0),
                count: ((acc[v] || {}).count || 0) + 1,
              };
            });

          return acc;
        }, {}),
      );

      const xaxis = seriesObj.map(([k, v]) => k).slice(0, 10);
      const values = seriesObj
        .map(([k, v]) => Math.floor(v.value / v.count))
        .slice(0, 10);

      const series = [
        {
          name: "Aduience city",
          data: values,
        },
      ];
      return {
        series,
        chartOptions: {
          ...barCharOpts,
          xaxis: {
            ...barCharOpts.xaxis,
            categories: xaxis,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },
      };
    },
    followerActivity() {
      const followerMetrics =
        (this.metrics.filter((metric) => metric.type === "online_followers") ||
          [])[0] || {};

      const { data = [] } = followerMetrics;

      const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      const series = days
        .reduce((acc, curr) => {
          return acc.concat({
            name: curr,
            data: data.reduce((a, c) => {
              if (dayjs(c.date).format("dddd") === curr) {
                Object.entries(c.count).forEach(([k, j], i) => {
                  a[i] = {
                    x: k,
                    y: ((a[i] || {}).y || 0) + j,
                    count: ((a[i] || {}).count || 0) + 1,
                  };
                });
              }
              return a;
            }, []),
          });
        }, [])
        .map((serie) => {
          if (serie.data.length === 0) {
            return {
              ...serie,
              data: Object.keys(timeTable).map((k) => ({ x: k, y: 0 })),
            };
          }
          return {
            name: serie.name,
            data: serie.data.map((d) => {
              return { x: d.x, y: Math.floor(d.y / d.count) };
            }),
          };
        });

      return {
        series,
        chartOptions: {
          dataLabels: {
            enabled: false,
          },
          colors: ["#008FFB"],
          xaxis: {
            tickAmount: 12,
            labels: {
              formatter: (value) => {
                return timeTable[value];
              },
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  > div {
    flex: 1;
  }
}
</style>
